// import { list } from 'ali-oss/lib/object'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/documentClassify',
    name: 'documentClassify',
    component: () => import('../views/documentClassify/documentClassify.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/search.vue')
  },
  {
    path: '/vipList',
    name: 'vipList',
    component: () => import('../views/vipList/vipList.vue')
  },
  {
    path: '/buyVip',
    name: 'buyVip',
    component: () => import('../views/buyVip/buyVip.vue')
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    component: () => import('../views/notice/noticeList.vue')
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: () => import('../views/notice/noticeDetail.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('../views/invite/invite.vue')
  },
  {
    path: '/integral',
    name: 'integral',
    component: () => import('../views/invite/integral.vue')
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import('../views/richText/aboutMe.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/richText/contactUs.vue')
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('../views/personalCenter/personalCenter.vue')
  },
  {
    path: '/myDocument',
    name: 'myDocument',
    component: () => import('../views/myDocument/myDocument.vue')
  },
  {
    path: '/richText',
    name: 'richText',
    component: () => import('../views/richText/richText.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/documentServices/article.vue')
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('../views/documentServices/edit.vue')
  },
  {
    path: '/tutor',
    name: 'tutor',
    component: () => import('../views/documentServices/tutor.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/buyVip/payment.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/buyVip/orderDetail.vue')
  },
  {
    path: '/docDetail',
    name: 'docDetail',
    component: () => import('../views/docDetail/docDetail.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/upload/upload.vue')
  },
  {
    path: '/fufei',
    name: 'fufei',
    component: () => import('../views/fufei.vue')
  },
]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from, next) => {
  console.log('router.afterEach',to,from,next);
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});
router.beforeEach((to, from, next) => {
  // 在每次导航之前滚动到页面顶部
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 可选的，使滚动平滑进行
  });
  next();
});

export default router
