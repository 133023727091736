<template>
  <div>
    <div>
      <div v-if="type == 1" class="contain1">
        <div class="contain_item1">账号密码登录</div>
        <div class="contain_item2">
          <div class="contain_item2_input">
            <img src="@/assets/image/icon12.png" alt="" />
            <el-input placeholder="请输入账号" v-model="pwdLogin.mobile">
            </el-input>
          </div>
          <div style="height: 20px"></div>
          <div class="contain_item2_input">
            <img src="@/assets/image/icon14.png" alt="" />
            <el-input placeholder="请输入密码" v-model="pwdLogin.password" type="password">
            </el-input>
          </div>
        </div>
        <div class="contain_item3">
          <div class="contain_item3_left">
            <div @click="isRemember = !isRemember" class="img">
              <img src="@/assets/image/icon6.png" alt="" v-if="isRemember" />
              <img src="@/assets/image/icon7.png" alt="" v-else />
            </div>
            <div>记住密码</div>
          </div>
          <div class="contain_item3_right" @click="
        type = 6;
      counting = false;
      timer = 60;
      ">
            忘记密码?
          </div>
        </div>
        <div class="contain_btn" @click="loginMethods">登录</div>
        <div class="loginMethods_contain">
          <!-- <div class="loginMethods_contain_item" v-if="type != 1">
          <img src="@/assets/image/icon9.png" alt="" />账号密码登录
        </div> -->
          <div class="loginMethods_contain_item" @click="
        type = 2;
      counting = false;
      timer = 60;
      ">
            <img src="@/assets/image/icon8.png" alt="" />注册账号
          </div>
          <div class="loginMethods_contain_item" @click="
        type = 3;
      counting = false;
      timer = 60;
      pwdLogin.password = '';
      ">
            <img src="@/assets/image/icon9.png" alt="" />验证码登录
          </div>
          <div class="loginMethods_contain_item" @click="type = 4">
            <img src="@/assets/image/icon10.png" alt="" />微信扫码登录
          </div>
          <div class="loginMethods_contain_item" @click="alipayLogin()">
            <img src="@/assets/image/icon11.png" alt="" />支付宝登录
          </div>
        </div>
      </div>
      <div v-if="type == 2" class="contain1">
        <div class="contain_item1">注册</div>
        <div class="code_contain">
          <div class="code_contain_number">
            <div class="contain_item2_input">
              <img src="@/assets/image/icon12.png" alt="" />
              <el-input placeholder="请输入账号/手机号" v-model="register.mobile">
              </el-input>
            </div>
            <!-- <div style="height: 20px"></div>
          <div class="code_contain_code">
            <div class="contain_item2_input">
              <img src="@/assets/image/icon13.png" alt="" />
              <el-input placeholder="请输入验证码" v-model="register.captcha">
              </el-input>
            </div>
            <div class="getCode">获取验证码</div>
          </div> -->
            <div style="height: 20px"></div>
            <div class="code_contain_code">
              <div class="contain_item2_input">
                <img src="@/assets/image/icon13.png" alt="" />
                <el-input placeholder="请输入验证码" v-model="register.code">
                </el-input>
              </div>
              <div class="getCode" @click="!counting && sendVerificationCode()">
                {{ counting ? `${timer}s后重新获取` : "获取验证码" }}
              </div>
            </div>
            <div style="height: 20px"></div>
            <div class="contain_item2_input">
              <img src="@/assets/image/icon14.png" alt="" />
              <el-input placeholder="请输入密码" v-model="register.password" type="password">
              </el-input>
            </div>
          </div>
        </div>
        <div class="contain_btn" @click="registerMethod()">注册</div>
      </div>
      <div v-if="type == 3" class="contain1">
        <div class="contain_item1">登录</div>
        <div class="code_contain">
          <div class="code_contain_number">
            <div class="contain_item2_input">
              <img src="@/assets/image/icon12.png" alt="" />
              <el-input placeholder="请输入账号" v-model="pwdLogin.mobile">
              </el-input>
            </div>
            <div style="height: 20px"></div>
            <div class="code_contain_code">
              <div class="contain_item2_input">
                <img src="@/assets/image/icon13.png" alt="" />
                <el-input placeholder="请输入验证码" v-model="pwdLogin.code">
                </el-input>
              </div>
              <div class="getCode" @click="!counting && sendVerificationCode()">
                {{ counting ? `${timer}s后重新获取` : "获取验证码" }}
              </div>
            </div>
          </div>
        </div>
        <div class="contain_btn" @click="loginMethods">登录</div>
        <div style="height: 35px"></div>
        <div class="loginMethods_contain" style="margin: 0 7px 0 55px">
          <!-- <div class="loginMethods_contain_item" v-if="type != 1">
          <img src="@/assets/image/icon9.png" alt="" />账号密码登录
        </div> -->
          <div class="loginMethods_contain_item" @click="
        type = 2;
      counting = false;
      timer = 60;
      ">
            <img src="@/assets/image/icon8.png" alt="" />注册账号
          </div>
          <div class="loginMethods_contain_item" @click="type = 1">
            <img src="@/assets/image/icon9.png" alt="" />账号密码登录
          </div>
          <div class="loginMethods_contain_item" @click="type = 4">
            <img src="@/assets/image/icon10.png" alt="" />微信扫码登录
          </div>
          <div class="loginMethods_contain_item" @click="alipayLogin()">
            <img src="@/assets/image/icon11.png" alt="" />支付宝登录
          </div>
        </div>
      </div>
      <div v-if="type == 4" class="contain1">
        <div class="contain_item1">微信扫码登录</div>
        <!-- 承载二维码容器 -->
        <!-- <div class="wx_dialog_toiast_delltet" id="login_container"></div> -->
        <div class="wx_login_code">
          <wxlogin :appid="appid" scope="snsapi_login" :theme="'black'" :redirect_uri="redirect_uri"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIGJvcmRlcjogbm9uZTsNCiAgd2lkdGg6IDIwMHB4Ow0KICBoZWlnaHQ6IDIwMHB4Ow0KfQ0KLmltcG93ZXJCb3ggLnN0YXR1c3sNCiAgZGlzcGxheTogbm9uZQ0KfQ=='">
          </wxlogin>
        </div>

        <div class="loginMethods_contain" style="display: flex; justify-content: center">
          <div class="loginMethods_contain_item" @click="type = 1">
            <img src="@/assets/image/icon9.png" alt="" />账号密码登录
          </div>
        </div>
      </div>
      <div v-if="type == 5" class="contain1">
        <div class="contain_item1">支付宝登录</div>
        <div class="loginMethods_contain" style="display: flex; justify-content: center">
          <div class="loginMethods_contain_item" @click="type = 1">
            <img src="@/assets/image/icon9.png" alt="" />账号密码登录
          </div>
        </div>
      </div>

      <div v-if="type == 6" class="contain1">
        <div class="contain_item1">修改密码</div>
        <div class="code_contain">
          <div class="code_contain_number">
            <div class="contain_item2_input">
              <img src="@/assets/image/icon12.png" alt="" />
              <el-input placeholder="请输入账号" v-model="editPwd.phone">
              </el-input>
            </div>
            <div style="height: 20px"></div>
            <div class="code_contain_code">
              <div class="contain_item2_input">
                <img src="@/assets/image/icon13.png" alt="" />
                <el-input placeholder="请输入验证码" v-model="editPwd.code">
                </el-input>
              </div>
              <div class="getCode" @click="!counting && sendVerificationCode()">
                {{ counting ? `${timer}s后重新获取` : "获取验证码" }}
              </div>
            </div>
            <div style="height: 20px"></div>
            <div class="contain_item2_input">
              <img src="@/assets/image/icon14.png" alt="" />
              <el-input placeholder="请输入新密码" v-model="editPwd.password" type="password">
              </el-input>
            </div>
          </div>
        </div>
        <div class="contain_btn" @click="editPassword">确认修改</div>
      </div>
    </div>
  </div>
</template>

<!-- <script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script> -->
<script>
import { postJSON } from "/utils/request";
// import qs from 'qs'
import wxlogin from "vue-wxlogin";
export default {
  props: ["backLoginInfo", "inviteCode", "bindPhone", "backalipayLogin"],
  components: {
    wxlogin
  },
  data() {

    return {
      type: 1, //1密码 2注册 3验证码 4微信扫码 5支付宝扫码 6忘记密码
      isRemember: false,
      input4: "",
      register: {
        password: "",
        code: "",
        mobile: "",
      },
      pwdLogin: {
        password: "",
        code: "",
        mobile: "",
      },
      editPwd: {
        password: "",
        code: "",
        phone: "",
      },
      bindinfo: {
        code: "",
        phone: "",
      },
      counting: false,
      timer: 60,
      interval: null,
      Wechat_authorized_login: false,
      appid: 'wx8b05aa3a8934467c',
      redirect_uri: 'https%3A%2F%2Fwww.yjx37.com%2F%23%2F',
      // appid:'',
    };
  },
  // created() {
  //   if (this.type == 4) {
  //     this.Get_wechat_callback_information();

  //   }
  // },
  mounted() {
    // 如果rememberMe为true，从localStorage中获取已存储的账号密码，并设置复选框状态为勾选
    if (localStorage.rememberedMobile && localStorage.rememberedPassword) {
      this.pwdLogin.mobile = localStorage.rememberedMobile;
      this.pwdLogin.password = localStorage.rememberedPassword;
      this.isRemember = true;
    }
    // if (this.type == 4) {
    //   this.get_wx_qrcode()
    // }
  },
  methods: {
    registerMethod() {
      if (
        !this.register.mobile ||
        !this.register.password ||
        !this.register.code
      ) {
        this.$message.error("请输入完整登录信息");
        return;
      }
      let obj = {
        ...this.register,
        invite_code: this.inviteCode
      }
      postJSON("/user/register/mobile", obj).then((res) => {
        console.log(res);
        this.token = res.data.token;
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
        this.$emit("backLoginInfo", res.data.token);
      });
    },
    loginMethods() {
      if (this.isRemember) {
        localStorage.rememberedMobile = this.pwdLogin.mobile;
        localStorage.rememberedPassword = this.pwdLogin.password;
      } else {
        localStorage.removeItem("rememberedMobile");
        localStorage.removeItem("rememberedPassword");
      }
      postJSON("/user/login/mobile", this.pwdLogin).then((res) => {
        console.log('/user/login/mobile', res);
        if (res.message !== 'success') {
          this.$message.error(res.message);
          return;
        }
        this.token = res.data.token;
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
        this.$emit("backLoginInfo", res.data.token);
      });
    },
    editPassword() {
      // editPwd: {
      //   password: "",
      //   code: "",
      //   phone: "",
      // },
      if (!this.editPwd.password || !this.editPwd.code || !this.editPwd.phone) {
        this.$message.error("请填写账户信息");
        return;
      }
      postJSON("/user/password/update", this.editPwd).then((res) => {
        console.log(res);
        if (res.message == "success") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "修改失败：" + res.message,
            type: "error",
          });
        }
        clearInterval(this.interval);
        this.counting = false;
        this.timer = 60;
        this.$emit("backLoginInfo");
      });
    },
    submitBinPhone() {
      let obj = {
        ...this.bindinfo,
        code: window.localStorage.getItem('wxcode')
      }
      console.log('扫码绑定返回值', obj);

      // postJSON("/wxchat/callback", obj).then((res) => {
      //   console.log('扫码绑定返回值',res);
      //   if (res.message !== 'success') {
      //     this.$message.error(res.message);
      //     return;
      //   }

      //   clearInterval(this.interval);
      //   this.counting = false;
      //   this.timer = 60;


      //   this.token = res.data.token;
      //   window.localStorage.setItem("token", res.data.token);
      //   window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
      //   this.$emit("backLoginInfo", res.data.token);


      // });
    },
    sendVerificationCode() {
      // 模拟发送验证码的逻辑

      var obj; //3验证码登录  2手机号注册
      if (this.bindPhone) {
        if (!this.bindinfo.phone) {
          this.$message.error("请输入手机号");
          return;
        }
      } else {
        if (this.type == 3) {
          if (!this.pwdLogin.mobile) {
            this.$message.error("请输入手机号");
            return;
          }
          obj = {
            phone: this.pwdLogin.mobile,
            type: 3,
          };
        } else if (this.type == 2) {
          if (!this.register.mobile) {
            this.$message.error("请输入手机号");
            return;
          }
          obj = {
            phone: this.register.mobile,
            type: 1,
          };
        } else if (this.type == 6) {
          if (!this.editPwd.phone) {
            this.$message.error("请输入手机号");
            return;
          }
          obj = {
            phone: this.editPwd.phone,
            type: 2,
          };
        }
      }

      // 设置定时器，每秒更新倒计时
      this.counting = true;
      postJSON("/send/sms", obj).then((res) => {
        console.log("zhixingwo ", res);
        this.startCountdown();
        // this.$message({
        //   message: res.message,
        // });
      });
    },
    startCountdown() {
      // 设置定时器，每秒更新倒计时
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          console.log("验证码参数：", this.timer);

          this.timer--;
        } else {
          // 倒计时结束时清除定时器
          clearInterval(this.interval);
          this.counting = false;
          this.timer = 60;
        }
      }, 1000);
    },
    alipayLogin() {
      this.$emit("backalipayLogin", 'ok')
    }
  },
};
</script>

<style lang="scss" scoped>
.contain1 {
  width: 424px;
  height: 440px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px 0;
  box-sizing: border-box;

  .contain_item1 {
    margin: 0 0 30px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #333333;
    text-align: center;
  }

  .contain_item2 {
    margin: 0 55px 15px;

    .contain_item2_input {
      width: 314px;
      height: 50px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      ::v-deep .el-input__inner {
        border: none !important;
        padding-left: 0;
      }
    }
  }

  .contain_item3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 55px;

    .contain_item3_left {
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;

      .img,
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .contain_item3_right {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .contain_btn {
    width: 314px;
    height: 50px;
    background: linear-gradient(90deg, #23c48c, #33da61);
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
    margin: 20px 55px 50px;
    cursor: pointer;
  }

  .loginMethods_contain {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 20px 0 55px;

    .loginMethods_contain_item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
      margin-bottom: 15px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }

  .code_contain {
    margin: 0 55px;

    .code_contain_number {
      .contain_item2_input {
        width: 314px;
        height: 50px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;

        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        ::v-deep .el-input__inner {
          border: none !important;
          padding-left: 0;
        }
      }
    }

    .code_contain_code {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .contain_item2_input {
        width: 186px;
        height: 50px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;

        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        ::v-deep .el-input__inner {
          border: none !important;
          padding-left: 0;
        }
      }

      .getCode {
        width: 108px;
        height: 50px;
        background: #23dc5a;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
        // margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}

.wx_dialog_toiast {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9854647;
  background: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .wx_dialog_toiast_delltet {
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wx_dialog_button_delete {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.wx_login_code {
  width: 196px;
  height: 196px;
  margin: 11px 67px;
  z-index: 10;
}

::v-deep .wrp_code,
.impowerBox {
  text-align: start !important;
}
</style>