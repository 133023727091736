import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import request from '/utils/request';
import ElementUI from 'element-ui';
import VXETable from 'vxe-table'
import clipboard from 'clipboard';

import VueClipboard from 'vue-clipboard2'
import 'vxe-table/lib/style.css'
import 'element-ui/lib/theme-chalk/index.css';


// let baseURL = 'https://moredoc.mnt.ltd/api';
// let version = '/v1';
// axios.defaults.baseURL = baseURL + version; //接口地址
Vue.prototype.$imgURL = `https://www.yjx37.com`; // 正式图片路径
Vue.prototype.$invitList = `https://www.yjx37.com/#/`; // 正式邀请路径
// Vue.prototype.$imgURL=`https://yujinxiang.test03.qcw800.com`; // 测试图片路径
// Vue.prototype.$invitList=`https://yujinxiang.test03.qcw800.com/#/`; // 测试邀请路径
// Vue.prototype.$imgURL=`http://192.168.51.67:8880`; // 本地图片路径
// Vue.prototype.$invitList=`http://192.168.51.67:8080/#/`; // 本地邀请路径、
Vue.prototype.clipboard = clipboard;

Vue.prototype.$http = axios;
Vue.prototype.$request = request;
// Vue.prototype.$message = Message;



Vue.use(ElementUI);
Vue.use(VXETable)
Vue.use(VueClipboard)

Vue.config.productionTip = false
// router.beforeEach((to, from, next) => {
//   console.log('最外部登录扫码返回值', to.query.code);
//   console.log('from', from);
//   console.log('next', next);

//   if (to.query.code == undefined) {
//     const token = window.localStorage.getItem('token')
//   console.log('Degnlu!!!!token', token);

//     if (token) { //如果有就直接到首页咯
//       next();
//     } else {
//       if (to.path == '/') { //如果是登录页面路径，就直接next()
//         next();
//       } else { //不然就跳转到登录;
//         next('/');
//       }
//     }
//   } else {
//     let code = to.query.code
//     console.log('else登录扫码返回值', code);
//     window.localStorage.setItem('wxcode', code)
//     // next('/');

//     // callBack(code).then(res => {
//     //   window.sessionStorage.setItem('X-Access-Token', res.data.token)
//     //   next('/index/mainPage');
//     // })
//   }
// });


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
